import { Ability  } from '@casl/ability';

const manager=[
      {
        action: 'read',
        subject: 'Dashboard'
      }
];




export default new Ability(manager);