import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import Spinner from "./views/spinner/Spinner";
import "antd/dist/antd.css";
import "./assets/scss/style.scss";
import "./assets/scss/estilosPersonalizados.css";
import { AbilityContext } from '../src/redux/permissions/Can';
import ability from '../src/redux/permissions/defineAbility';


const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./app")), 0);
    })
);

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
             <App />
        </AbilityContext.Provider>
      <div id="divCargando"></div>
  </Suspense>,
  document.getElementById("root")
);
